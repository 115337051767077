.custom-wrap-table .ant-table-cell {
  white-space: normal; 
  word-wrap: break-word; 
}

.custom-wrap-table .ant-table-tbody .ant-table-row {
  background: #fff;
  border: 1px solid #ebedf0; /* Apply border to rows */
  border-radius: 8px;
  height: 60px;
}

.custom-wrap-table .ant-table-tbody > tr:hover {
  background: #f5f5f5; /* Optional: hover effect */
}