.add-room-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  align-items: center;
  gap: 30px;
}

.row-full{
  display: flex;
  /* align-items: center; */
  /* justify-content: space-evenly; */
  background-color: #fff;
  margin-bottom: 0px;
}

.row-textarea{
  /* display: flex; */
  width: 1195px ;
  height: auto;
}

.rowWithOutGap {
  display: flex;
  align-items: center;
}

.row .ant-form-item {
  flex: 1;
}

.flex-half {
  flex: 0.3;
}

.margin-auto {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 28px;
}

.save-section {
  text-align: right;
  margin-top: 20px;
}

.ant-input,
.ant-select-selector {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.ant-input-textarea {
  min-height: 80px;
}

.applicableContainer {
  padding: 26px;
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #f0f0f0;
}

.applicable-for-section .ant-radio-group {
  display: flex;
  gap: 20px;
}

.lable {
  font-weight: bold;
}

.form-scrollable-content {
  flex: 1;
  overflow-y: auto;
  padding-right: 16px;
  margin-left: 24px;
}

.save-section {
  position: sticky;
  bottom: 0;
  background-color: #f6f7f9;
  padding: 16px 24px;
  text-align: right;
  border-top: 1px solid #e0e7fe;
  z-index: 1;
}

.export-button {
  margin-right: 8px;
  font-weight: 700;
  color: #333;
  border-radius: 4px;
  background-color: #fff;
  border-color: #e0e7fe;
  height: 40px;
  padding: 9px 18px;
}

.quick-settings {
  color: rgb(46, 86, 242);
  font-weight: 700;
  border-radius: 4px;
  background-color: #fff;
  border-color: rgb(46, 86, 242);
  height: 40px;
  margin-right: 8px;
}

.setting-icon {
  margin-right: 8px;
  width: 37px !important;
  font-weight: 700;
  color: #c2cadd;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  border-color: #e0e7fe;
  height: 40px;
  padding: 9px 18px;
}

:where(.css-dev-only-do-not-override-1hpnbz2).ant-btn.ant-btn-icon-only
  .anticon {
  font-size: 20px;
}

:where(.css-dev-only-do-not-override-1hpnbz2).ant-btn-variant-outlined:not(
    :disabled
  ):not(.ant-btn-disabled):hover {
  color: #c2cadd;
  background-color: rgb(255, 255, 255);
  border-color: #e0e7fe;
}
:where(.css-dev-only-do-not-override-1hpnbz2).ant-btn-variant-outlined:not(
    :disabled
  ):not(.ant-btn-disabled):hover {
  color: #333;
  border-color: #e0e7fe;
  background-color: #fff;
}

.add-button {
  margin-right: 8px;
  background-color: #ff971e;
  font-weight: 700;
  height: 40px !important;
  padding: 9px 18px;
  border-radius: 4px;
}

:where(.css-dev-only-do-not-override-1hpnbz2).ant-btn-variant-solid:not(
    :disabled
  ):not(.ant-btn-disabled):hover {
  background-color: #ff971e;
}

:where(.css-dev-only-do-not-override-1hpnbz2).ant-input-search
  .ant-input-affix-wrapper {
  height: 40px;
  border-radius: 0;
}

:where(.css-dev-only-do-not-override-1hpnbz2).ant-input-search
  .ant-input-search-button {
  height: 40px;
}

:where(.css-dev-only-do-not-override-1hpnbz2).ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item-icon,
:where(.css-dev-only-do-not-override-1hpnbz2).ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-item-icon {
  font-size: 16px;
}

@media (max-width: 600px) {
  /* .mainContainer {
      padding: 10px;
    } */

  .form-scrollable-content {
    padding: 16px;
    margin-left: 0;
  }

  .row {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .rowWithOutGap {
    display: flex;
    flex-direction: column;
    /* gap: 15px; */
  }

  .ant-form-item {
    width: 100%;
    margin-bottom: 0px;
  }

  .margin-auto {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
    margin-top: 0px;
  }

  .save-section {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
  }

  .save-section button {
    width: 48%;
  }

  .catBtn {
    margin-top: 10px;
  }
}

/* .product-table-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.table-filter-section {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.ant-table-thead > tr > th {
  background-color: #f5f7fa;
  font-weight: bold;
  text-align: left;
  color: #333;
  padding: 16px;
  border: none;
}

.ant-table-column-sorters {
  display: none;
}

.ant-table-tbody > tr > td {
  padding: 16px;
  border-bottom: none;
}

.custom-product-row {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); 
}

.custom-product-row:hover {
  background-color: #f5f7fa;
}

.ant-checkbox-wrapper {
  margin-left: 8px;
}

.ant-typography {
  color: blue;
  cursor: pointer;
}

.ant-typography-red {
  color: red;
  cursor: pointer;
} */
