.add-room-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 16px;
}

.row .ant-form-item {
  flex: 1;
}

.flex-half {
  flex: 0.3;
}

.margin-auto {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 28px;
}

.save-section {
  text-align: right;
  margin-top: 20px;
}

.ant-input,
.ant-select-selector {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.ant-input-textarea {
  min-height: 80px;
}

.applicableContainer {
  padding: 26px;
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #f0f0f0;
}

.applicable-for-section .ant-radio-group {
  display: flex;
  gap: 20px;
}

.lable {
  font-weight: bold;
}

.form-scrollable-content {
  flex: 1;
  overflow-y: auto;
  padding-right: 16px;
  margin-left: 24px;
}

.save-section {
  position: sticky;
  bottom: 0;
  background-color: #f6f7f9;
  padding: 16px 24px;
  text-align: right;
  border-top: 1px solid #e0e7fe;
  z-index: 1;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

.mainContainer {
  padding: 24px;
}

.header {
  display: flex;
  align-items: center;
}

.back-icon {
  cursor: pointer;
  margin-right: 12px;
  color: #325df2;
  font-size: 18px;
}

.form-container {
  margin-top: 10px;
  background-color: #fafafa;
  border-radius: 12px;
  border: 1px solid #e0e7fe;
  padding: 20px;
}

.form-scrollable-content {
  overflow-y: auto;
}

.form-section-title {
  font-size: 16px;
  margin-bottom: 20px;
}

.staff-table {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #eaeaea;
}

.staff-row {
  margin-bottom: 16px;
}

.staff-row .delete-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-staff-button {
  margin-top: 8px;
  padding: 0;
  color: #ff6f00;
}

.form-footer {
  text-align: right;
  margin-top: 20px;
}

.ant-select,
.ant-input {
  border-radius: 8px;
}

.ant-select-selection-item {
  font-size: 14px;
}

h4 {
  font-size: 14px;
  color: #5f6368;
  font-weight: 500;
}

.ant-btn-primary {
  border-radius: 8px;
}

/* Enable Online Booking */
.service-add-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 15px;
  height: auto;
}

.booking-toggle {
  display: flex;
  align-items: center;
}

.label {
  font-weight: bold;
  font-size: 16px;
  margin-right: 20px; /* Spacing between the label and the switch */
}

.online-booking-switch {
  margin-left: auto; /* Ensures switch stays on the right */
}

.form-row {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
}

.form-group {
  flex: 1;
}

.select-input {
  width: 100%;
  margin-top: 8px;
}

.note-section {
  margin-bottom: 24px;
}

.textarea-input {
  width: 100%;
  height: 100px;
  margin-top: 8px;
}

/* Avilable Disp */
/* Styles to control the height, width, and overflow of the Select component */
.select-input .ant-select-selection-overflow {
  max-height: 40px; /* Adjust height as needed */
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

/* Custom styling to handle the multi-select selected items, ensuring overflow is handled */
.select-input .ant-select-selection-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Styling for the "more options" tag (+ 3...) */
.select-input .ant-select-selection-overflow-item-suffix {
  margin-left: 4px;
  font-size: 12px;
  color: #888;
}
