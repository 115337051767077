.modal-description {
    text-align: center;
    font-size: 16px;
    width: 40%;
    margin-bottom: 20px;
    color: #333;
    display: flex;
    justify-items: center;
    margin-left: 30%;
  }
  
  .modal-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #f7f7f9;
    border-radius: 8px;
    text-align: center;
    width: 95%;
  }
  
  
  .modal-icon img {
    width: 56px;
    height: 56px;
    margin-bottom: 10px;

  }

  
  .modal-text {
    font-size: 14px;
    color: #555;
    margin-bottom: 16px;
    width: 40%;
  }
  
  .download-button {
    color: white;
    background-color: rgb(46, 86, 242);
    height: 38px;
    width: 19%;
    font-weight: 500;
    /* border-color: rgb(46, 86, 242); */
  }
  
  .upload-dragger {
    padding: 20px;
    /* background-color: #f7f7f9;
    border: 1px dashed #d9d9d9;
    border-radius: 8px; */
  }
  
  .upload-text {
    font-size: 14px;
    color: #555;
    margin-top: 10px;
  }

  :where(.css-dev-only-do-not-override-1hpnbz2).ant-upload-wrapper .ant-upload-drag {
    background-color:#f7f7f9;
    border: none;
  }
  