.name-input-container {
    display: flex;
    /* border: 1px solid #e0e7fe;
    border-radius: 8px; */
    padding: 0px;
    background-color: white;
    align-items: center;
    border-radius: 10%;
  }
  
  .name-input {
    flex: 1;
    padding: 5px;
    border: none !important;
  }

 
  .divider {
    width: 1px;
    height: 26px;
    background-color: #e0e7fe;
    margin: 0 8px;
  }