.modal-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 0px;
}

.modal-message {
  font-size: 18px;
  margin: 0px;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 50px;
}

.vl {
  border-left: 1px solid #d9d9d9;
  height: 50px;
  margin: 0 10px;
}

.modal-divider {
  border-top: 2px solid #d9d9d9;
  border: none;
  border-top: 1px solid #d9d9d9;
}

.cancel-button,
.confirm-button {
  flex: 0.5;
  height: 50px;
}

.ant-modal-content {
  border-radius: 8px !important;
}

.ant-modal-body {
  padding: 0px 0px 0px 0px !important;
  /* height: 300px !important;  */
}

/* Customizing the table header to match the style */
.ant-table-thead > tr > th {
  background-color: #f5f7fa;
  font-weight: bold;
  text-align: left;
  color: #333;
  border: none;
  padding: 16px 8px;
}

/* Customizing table body rows */
.ant-table-tbody > tr > td {
  border: none; /* Remove borders for table rows */
  padding: 16px 8px; /* Add padding for spacing */
  vertical-align: middle; /* Align content vertically */
}

/* Row hover effect to match the design */
.ant-table-tbody > tr:hover {
  background-color: #fafafa;
}

/* No lines between rows */
.custom-row + .custom-row {
  border-top: none;
}

/* Consistent column width styling */
.ant-table-cell {
  white-space: nowrap;
}

/* Switch alignment */
.ant-table-cell > .ant-switch {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
