.add-room-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  align-items: center;
  gap: 20px;
  /* margin-bottom: 16px; */
}

.row-full{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-evenly; */
  background-color: #fff;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.row .ant-form-item {
  flex: 1;
}

.flex-half {
  flex: 0.3;
}

.margin-auto {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 28px;
}

.save-section {
  text-align: right;
  margin-top: 20px;
}

.ant-input,
.ant-select-selector {
  /* height: 40px; */
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.ant-input-textarea {
  min-height: 80px;
}

.applicableContainer {
  padding: 26px;
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #f0f0f0;
}

.applicable-for-section .ant-radio-group {
  display: flex;
  gap: 20px;
}

.lable {
  font-weight: bold;
}

.form-scrollable-content {
  flex: 1;
  overflow-y: auto;
  padding-right: 16px;
  margin-left: 24px;
}

.save-section {
  position: sticky;
  bottom: 0;
  background-color: #f6f7f9;
  padding: 16px 24px;
  text-align: right;
  border-top: 1px solid #e0e7fe;
  z-index: 1;
}

@media (max-width: 600px) {
  /* .mainContainer {
    padding: 10px;
  } */

  .form-scrollable-content {
    padding: 16px;
    margin-left: 0;
  }

  .row {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .ant-form-item {
    width: 100%;
    margin-bottom: 0px;
  }

  .margin-auto {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
    margin-top: 0px;
  }

  .save-section {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
  }

  .save-section button {
    width: 48%;
  }

  .catBtn {
    margin-top: 10px;
  }
}
