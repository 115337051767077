.mainContainer {
  background-color: #f0f3f8;
  padding: 12px 22px !important;
  height: calc(100vh - 30px) !important;
}
body {
  background: #f0f3f8;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica,
    Segoe UI, Arial, Roboto, PingFang SC, miui, Hiragino Sans GB,
    Microsoft Yahei, sans-serif;
}
textarea {
  resize: vertical !important;
}
/* inputbox */
.ant-input {
  /* border-radius: 4px;
  border: 1px solid #d9d9d9;
  font-size: 14px;
  padding: 8px;
  background-color: #ffffff;
  color: #595959; */
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  height: 40px;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px !important;
  transition: all 0.3s;
}

.ant-input::placeholder {
  color: #bfbfbf;
}

.textWithPrifix {
  color: #325df2;
  height: 30px;
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.textWithSuffix {
  color: #325df2;
  display: flex;
  height: 30px;
  align-items: center;
  margin-left: 5px;
}

/* dropdown */
.ant-select-selector {
  height: 46px !important;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  font-size: 14px;
  background-color: #ffffff;
  padding: 8px;
  display: flex;
  align-items: center;
}

.ant-select-single {
  height: 40px;
}

:where(.css-dev-only-do-not-override-1hpnbz2).ant-form-vertical
  .ant-form-item:not(.ant-form-item-horizontal)
  .ant-form-item-label {
  padding: 0px 0px 5px;
}

:where(.css-dev-only-do-not-override-1hpnbz2).ant-form-vertical
  .ant-form-item:not(.ant-form-item-horizontal)
  .ant-form-item-label
  > label {
  font-size: 16px;
  color: #666;
}

.ant-select-selection-placeholder {
  color: #bfbfbf;
}

.ant-select-arrow {
  color: #8c8c8c;
}

.ant-input-affix-wrapper {
  padding-top: 0;
  padding-bottom: 0;
}

.ant-switch.ant-switch-checked {
  background-color: #00d96d;
}

:where(
    .css-dev-only-do-not-override-1hpnbz2
  ).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background-color: #00d96d;
}

/* table style */
.ant-table-header {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  display: flex;
  background: #fafbff;
  border: 1px solid #e0e7fe;
  margin-bottom: 4px;
  align-items: center;
  border-spacing: 0;
}

.ant-table-header .ant-table-cell {
  font-size: 12px;
  color: #666;
  font-weight: 700;
}

.ant-table-body::-webkit-scrollbar {
  width: 0;
}

.ant-table-body {
  scrollbar-width: thin;
}

.ant-table-body {
  -ms-overflow-style: none;
}

.ant-table-body {
  overflow-y: hidden;
  overflow-x: auto;
}

:where(.css-dev-only-do-not-override-1hpnbz2).ant-table-wrapper .ant-table {
  line-height: 0.5;
}

.ant-pagination-item {
  color: #2e56f2 !important;
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
  font-weight: 700 !important;
}

.ant-pagination .ant-pagination-item a {
  color: #2e56f2;
}

.ant-pagination .ant-pagination-item-active a {
  color: #fff !important;
}

.ant-pagination-item.ant-pagination-item-active {
  color: #fff !important;
  background: #2e56f2 !important;
}

:where(.css-dev-only-do-not-override-1hpnbz2).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td {
  border: none;
}

:where(.css-dev-only-do-not-override-1hpnbz2).ant-table-wrapper
  .ant-table-thead
  > tr
  > th {
  font-weight: 400;
  font-size: 14px;
  background-color: #fafbff;
}

:where(.css-dev-only-do-not-override-1hpnbz2).ant-table-wrapper
  .ant-table
  .ant-table-header {
  border-radius: 4px;
}
.notes-modal {
  border-radius: 16px !important;
}

.notes-modal .ant-modal-content {
  padding: 10px 10px 8px 10px;
}
.notes-modal .modal-title {
  font-weight: 700;
  padding: 25px 0px 0px 0px;
  color: #323233;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}
.notes-modal .modal-message {
  color: #646566;
  padding: 8px 8px 24px 8px;
  text-align: center;
  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: 14px;
  line-height: 20px;
}
/* deletemodel style */
.delete-modal .ant-modal-content {
  padding: 0;
}

.stock-review-modal {
  width: 400px !important;
}

.stock-review-modal .ant-modal-content {
  padding: 0;
}

.stock-review-modal .ant-modal-header {
  padding: 12px;
}

.product-card .ant-card-head {
  background: rgba(46, 86, 242, 0.8);
  color: #fff;
}
/* add and edit model */
.add-model .ant-modal-content {
  padding: 0;
  overflow: hidden;
}
.add-model .ant-modal-title {
  padding-top: 24px;
  padding-left: 24px;
}

:where(.css-dev-only-do-not-override-1hpnbz2).ant-select-outlined:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border: 1px solid #d9d9d9;
  height: 40px;
  background: #ffffff;
}

/* .main-table table {

  border-collapse: collapse!important;

}



.main-table th {

  background-color: green!important;

  Color: white!important;

}



.main-table th,

.main-table td {

  width: 150px!important;

  text-align: center!important;

  border: 1px solid black!important;

  padding: 5px!important;

}



.main-table .geeks {

  border-right: hidden!important;

}



.main-table  {

  border-collapse: separate!important;

  border-spacing: 0 15px!important;

}



.main-table h1 {

  color: green!important;

} */

.main-table .ant-table-tbody .ant-table-row {
  background: #fff;
  border: 1px solid #ebedf0; /* Apply border to rows */
  border-radius: 8px;
  height: 60px;
}

.main-table .ant-table-tbody > tr:hover {
  background: #f5f5f5; /* Optional: hover effect */
}

.main-bg-table .ant-table-body table {
  background: #fff !important;
}

.main-bg-table .ant-table-tbody .ant-table-row {
  background: #fff;
  border: 1px solid #ebedf0 !important;
}

.main-bg-table .ant-table-cell {
  background: #fafbff !important;
}

div.ant-table-body {
  margin-top: -6px;
}

.ant-table-body table {
  border-spacing: 0px 8px;
  background: rgb(246, 247, 249);
}

.ant-table-body table .ant-table-tbody tr.custom-row-spacing,
.ant-table-body table .ant-table-tbody tr.custom-row-spacing:hover {
  background-color: white !important; /* White background for each row */
  border-radius: 8px; /* Rounded corners to enhance the appearance */
  font-weight: 400;
  font-size: 17px;
  line-height: 2.2;
}

.ant-table-body table .ant-checkbox-input,
.ant-table-body table .ant-checkbox-inner {
  height: 16px;
  width: 16px;
}

.custom-row-spacing .ant-table-cell-row-hover {
  margin-bottom: 12px; /* Add space between rows */
  background-color: white !important; /* White background for each row */
  border-radius: 8px; /* Rounded corners to enhance the appearance */
  padding: 12px;
  border-spacing: 0 10px;
  border: 5px solid #00d96d;
  font-weight: 400;
  font-size: 17px;
  line-height: 2.2;
}

.ant-table-column-title {
  font-size: 14px;
  font-weight: 500;
}

/* .ant-table-header{

  background-color: white !important;

} */

/* .ant-table-header{

  background-color: white !important;

} */

/* .ant-table-thead{

  background-color: white !important;

} */

/* .ant-table-header{

  background-color: white !important;

} */

/* .ant-table-cell ant-table-selection-column{

  background-color: white !important;

} */

.ant-table-cell {
  background-color: white !important;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}

.bk-view display-def view-headerItem {
  line-height: 20px;
  padding: 8px 8px 8px 12px;
  background: #fff;
  font-size: 12px;
  font-weight: 700;
  color: #666;
}
:where(.css-dev-only-do-not-override-1hpnbz2).ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  position: relative;
}
:where(.css-dev-only-do-not-override-1hpnbz2).ant-table-wrapper
  .ant-table-thead
  > tr
  > th {
  font-size: 14px;
  font-weight: 700;
  color: #666;
  letter-spacing: 0px;
}

.duplicate-btn,
.duplicate-btn:hover {
  margin-left: 16px;
  border-color: #2e56f2;
  color: #2e56f2;
  font-weight: 700;
  height: 40px;
  padding: 9px 18px;
  border-radius: 4px;
  background: #fff;
}

.deleteall-btn {
  color: rgb(245, 63, 63);
  font-weight: 700;
  height: 40px;
  padding: 9px 18px;
  border-radius: 4px;
  background: #fff;
  border-color: #e0e7fe;
}
.status-tag {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0 9px;
  line-height: 24px;
  font-size: 12px;
  font-weight: 600;
  width: fit-content;
  border-radius: 100px 100px 100px 100px;
}

.status-tag.Pending {
  color: #ff971e;
  background: rgba(255, 151, 30, 0.1);
}

.status-tag.Approved {
  color: #00ad6f;
  background: rgba(0, 173, 111, 0.1);
}

.status-tag.Rejected {
  color: #e00018;
  background: rgba(224, 0, 24, 0.1);
}

.status-dot {
  top: 50%;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  box-sizing: content-box;
  margin-right: 10px;
}

.status-tag.Pending .status-dot {
  background: #ff971e;
}

.status-tag.Approved .status-dot {
  background: #00ad6f;
}

.status-tag.Rejected .status-dot {
  background: #e00018;
}

.status-text {
  vertical-align: middle;
}

.active {
  background-color: #4caf50;
}

.inactive {
  background-color: #c0c0c0;
}

.anticon.anticon-caret-up.ant-table-column-sorter-up.active,
.anticon.anticon-caret-down.ant-table-column-sorter-down.active {
  background-color: transparent !important;
}

.vertical-line {
  display: inline-block;
  content: "";
  width: 3px;
  height: 12px;
  background: #264ab5;
  margin-right: 10px;
}
.custom-steps .ant-steps-item-icon {
  position: relative;
}

.custom-steps .ant-steps-item-icon::before {
  content: "";
  position: absolute;
  top: -80px; /* Adjust as needed to position the image above the dot */
  left: 50%;
  transform: translateX(-50%);
  width: 56px; /* Width of your icon */
  height: 56px; /* Height of your icon */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.custom-steps .ant-steps-item .ant-steps-item-content {
  width: fit-content;
  margin-left: 40px;
  text-align: justify;
}

.custom-steps .ant-steps-item.step-creation .ant-steps-item-icon::before {
  background-image: url("../../public/images/logo/Def_Icon_Inventory_Stock_Detail_Step_Creat.png"); /* Replace with your icon path */
}

.custom-steps .ant-steps-item.step-pending .ant-steps-item-icon::before {
  background-image: url("../../public/images/logo/Def_Icon_Inventory_Stock_Detail_Step_Pended.png"); /* Replace with your icon path */
}

.custom-steps .ant-steps-item.step-reject .ant-steps-item-icon::before {
  background-image: url("../../public/images/logo/Def_Icon_Inventory_Stock_Detail_Step_Rejected.png"); /* Replace with your icon path */
}

.custom-steps .ant-steps-item.step-approved .ant-steps-item-icon::before {
  background-image: url("../../public/images/logo/Def_Icon_Inventory_Stock_Detail_Step_Approved.png"); /* Replace with your icon path */
}

.custom-steps .ant-steps-item.step-pended .ant-steps-item-icon::before {
  background-image: url("../../public/images/logo/Def_Icon_Inventory_Stock_Detail_Step_Pending.png"); /* Replace with your icon path */
}

.custom-steps .ant-steps-item.reject-line .ant-steps-item-tail::after {
  background-color: rgb(
    224,
    0,
    24
  ) !important; /* Change line to red when rejected */
}

.custom-steps .ant-steps-item.step-reject .ant-steps-icon .ant-steps-icon-dot {
  background: rgb(
    224,
    0,
    24
  ) !important; /* Change text color to red when rejected */
}
.product-card-view {
  border: none;
  border-radius: 8px;
}
.product-card-view .ant-card-head {
  border: none;
}
.ant-picker-panel-layout {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  align-items: stretch;
}
.ant-picker-presets {
  display: flex;
  flex-direction: row;
  gap: 20px;
  min-width: 100%;
  max-width: 100%;
  height: 40px;
}
.ant-picker-presets ul {
  height: 26px;
  display: flex;
  flex-direction: row;
  flex: auto;
  list-style: none;
  overflow: auto;
  margin: 0;
  padding: 8px;
  border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
  width: max-content;
  gap: 10px;
}
.ant-picker-presets ul li {
  margin: 0px !important;
  color: #2e56f2 !important;
  background: #f0f5ff !important;
  border: 1px solid #abc4ff !important;
  cursor: pointer !important;
  overflow: none !important;
}
.ant-popover-arrow {
  display: none !important;
}
.ant-popover-inner {
  padding: 0px !important;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
:where(.css-dev-only-do-not-override-ni1kz0).ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  display: none !important;
}

/* .selection-staff-modal .ant-modal-content {
  height: calc(100vh - 110px);
} */
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-top: 10px;
  row-gap: 10px;
}

.calendar-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 18px;
  height: 60px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 12px;
  cursor: pointer;
}

.calendar-cell.header {
  font-size: 16px;
  line-height: 22px;
}

.calendar-cell.selected {
  background-color: #e6f7ff;
  border-radius: 0px;
}

.calendar-cell.start {
  background-color: #2e56f2;
  color: white;
  border-radius: 12px 0px 0px 12px !important;
}

.calendar-cell.end {
  background-color: #2e56f2;
  color: white;
  border-radius: 0px 12px 12px 0px !important;
}
.scrollable-tabs .ant-tabs-nav {
  /* overflow-x: auto !important; */
  /* scrollbar-width: thin; */
}
.scrollable-tabs .ant-tabs-nav-list {
  overflow-x: auto !important;
  scrollbar-width: thin !important;
}
.scrollable-tabs .ant-tabs-nav-operations {
  display: none !important;
}
.scrollable-tabs.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0px 0px 0px 60px;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #333 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}
.ant-tabs {
  position: relative;
}
.ant-tabs .ant-tabs-extra-content {
  position: absolute;
  right: 0;
  background: #fff;
  padding: 0px 12px;
}
.ant-tabs .ant-tabs-tab {
  color: #a8b1c7 !important;
  font-size: 14px !important;
}
.ant-tabs .ant-tabs-tab:hover {
  color: #a8b1c7 !important;
}
.ant-tabs > .ant-tabs-nav {
  justify-content: space-between;
}
.day-card {
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}
.day-card-closed {
  cursor: not-allowed;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.day-header {
  background-color: #2e56f2;
  color: white;
  font-weight: bold;
  padding: 8px 0;
  border-radius: 8px 8px 0 0;
}
.day-header-closed {
  background-color: #f1f1f1;
  color: #999;
  font-weight: bold;
  padding: 8px 0;
  border-radius: 8px 8px 0 0;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #333 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}
.ant-tabs .ant-tabs-tab {
  color: #a8b1c7 !important;
  font-size: 14px !important;
}
.ant-tabs .ant-tabs-tab:hover {
  color: #a8b1c7 !important;
}
.react-tel-input .selected-flag {
  width: 90px;
}
.react-tel-input .selected-flag::after {
  content: attr(data-country-code); /* Show country code as attribute */
  margin-left: 8px;
  font-weight: bold;
  color: #374151;
}
.react-tel-input .selected-flag .arrow {
  display: none !important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
}
.react-tel-input .selected-flag {
  width: 95px !important;
}
.react-tel-input .country-list {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #c4c4c4 #ffffff; /* Scrollbar and track colors */
}

.react-tel-input .country-list::-webkit-scrollbar {
  width: 6px;
}

.react-tel-input .country-list::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 8px;
}

.react-tel-input .country-list::-webkit-scrollbar-thumb {
  background-color: #c4c4c4; /* Color of the scrollbar thumb */
  border-radius: 8px; /* Rounded scrollbar */
  border: 2px solid #ffffff; /* Adds padding around the scrollbar thumb */
}
.react-tel-input .flag-dropdown {
  background: transparent !important;
  border: none !important;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background: transparent !important;
}
.react-tel-input .flag-dropdown.open {
  background: transparent !important;
}
.react-tel-input input {
  padding-left: 50px; /* Align with flag dropdown */
}

:where(.css-dev-only-do-not-override-apn68).ant-badge.ant-badge-status
  .ant-badge-status-dot {
  width: 12px;
  height: 12px;
}
:not(.ant-layout-sider-collapsed).main-sider.ant-layout-sider {
  width: 240px !important;
  min-width: 240px !important;
  max-width: 240px !important;
}
:not(.ant-layout-sider-collapsed).main-sider.ant-layout-sider
  .ant-layout-sider-trigger {
  width: 240px !important;
}
.main-sider.ant-layout-sider .ant-menu-item {
  padding: 24px 32px;
  line-height: 22px;
  border-radius: 0px;
}
.main-sider.ant-layout-sider .ant-menu-item.ant-menu-item-selected {
  color: #fff !important;
  background: rgba(0, 0, 0, 0.15);
  font-weight: 700;
  font-size: 14px;
}
.main-sider.ant-layout-sider .ant-menu-item .ant-menu-title-content {
  padding-left: 40px;
}
.rotate-icon {
  display: inline-block;
  transition: transform 0.5s ease;
  transform: rotate(360deg);
}
.reverse-icon {
  transform: scaleX(-1);
}
.rotate-icon.reverse-icon {
  display: inline-block;
  transition: transform 0.5s ease;
  transform: scaleX(-1) rotate(-360deg);
}
.responsive-form {
  gap: 0px; /* Default gap for larger screens */
}

@media (max-width: 768px) {
  .responsive-form {
    gap: 24px; /* Increased gap for mobile screens */
  }
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  flex: none !important;
  margin-top: 0px;
}
.view-quote-wrap {
  padding: 20px 8px 20px 10px;
  position: relative;
}
.view-quote-wrap::after {
  position: absolute;
  content: "";
  top: 50%;
  right: -1px;
  width: 10px;
  height: 1px;
  box-sizing: content-box;
  background-color: #2e56f2;
}
.view-quote {
  background-color: #2e56f2;
  height: 100%;
  width: 1px;
  position: relative;
}
.view-quote::after {
  position: absolute;
  content: "";
  bottom: 0;
  right: 0;
  width: 10px;
  height: 1px;
  background-color: #2e56f2;
}
.view-quote::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 10px;
  height: 1px;
  background-color: #2e56f2;
}
.billing-input.ant-input-group-wrapper-outlined
  .ant-input-group-addon:first-child {
  background-color: #fff;
  border: 1px solid #d9d9d9 !important;
  border-right: none !important;
}
.billing-input.ant-input-group-wrapper-outlined .ant-input-group-addon {
  border: none !important;
}
.billing-input.ant-input-group .ant-input {
  border-left: none !important;
}
.tab-select-radio .ant-radio-button-wrapper-checked {
  background: #fff !important;
  border: none !important;
}
.tab-select-radio .ant-radio-button-wrapper {
  background: #f4f4f4;
  border: none !important;
}
.tab-select-radio
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background-color: #fff;
}
.tab-select-radio .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #f4f4f4 !important;
}
.single-selected-date.ant-picker-calendar .ant-picker-panel {
  border: none !important;
}
.single-selected-date.ant-picker-calendar .ant-picker-cell {
  border-radius: 12px;
}
.single-selected-date.ant-picker-calendar
  .ant-picker-cell
  .ant-picker-cell-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 12px;
}
.single-selected-date.ant-picker-calendar
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border: none !important;
}
.numberinput input[type="number"]::-webkit-inner-spin-button,
input[type="number"].numberinput::-webkit-inner-spin-button,
.numberinput input[type="number"]::-webkit-outer-spin-button,
input[type="number"].numberinput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.numberinput input[type="number"],
input[type="number"].numberinput {
  -moz-appearance: textfield;
}

.gft_view-bc {
  position: relative;
  z-index: 1;
  display: flex;
  height: 70px;
  padding: 16px 24px 0 16px;
}

.gft_view-info {
  flex: 1;
  max-width: 100%;
}
.gft_overflowText {
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gft_title {
  display: inline-block;
  font-family: SourceHanSansCN-Bold, PingFangSC-Semibold, PingFang SC;
  font-size: 16px;
  /* line-height: 32px; */
  font-weight: 700;
  color: rgba(176, 87, 77, 0.8);
  white-space: nowrap;
}
.gft_saleCardItem_text_code {
  margin-left: 10px;
  vertical-align: top;
  color: rgba(176, 87, 77, 0.8);
  white-space: nowrap;
}
.gft_view-apply {
  display: flex;
}
.gft_view-cardApply {
  cursor: pointer;
  border-bottom: 1px solid;
  font-size: 12px;
  color: rgba(176, 87, 77, 0.8);
}
.gft_bk-cardApply {
  display: inline-block;
  line-height: 20px;
}
.gft_cardApply_text_applicable {
  vertical-align: top;
}
.gft_icon-gengduo {
  font-size: 12px;
  margin-top: 1px;
  line-height: 20px;
}
.gft_view-singleUsageQty {
  display: flex;
  margin-left: 24px;
}
.gft_saleCardItem_text_singleUsageQty {
  vertical-align: top;
  color: rgba(176, 87, 77, 0.8);
}
.gft_display-def {
  position: relative;
}
.gft_view-bottom {
  position: relative;
  z-index: 1;
  display: flex;
  height: 40px;
  line-height: 40px;
  padding: 0 24px 0 16px;
  background: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0.3),
    hsla(0, 0%, 100%, 0)
  );
}
.gft_view-left {
  flex: 1;
}
.gft_bk-validDateText {
  font-size: 12px;
}
.gft_saleCardItem_text_valid {
  vertical-align: top;
  font-size: 12px;
  color: rgba(176, 87, 77, 0.8);
}
.gtf_bk-date {
  font-size: 12px;
  color: rgba(176, 87, 77, 0.8);
}
.gft_image-icon {
  position: absolute;
  left: auto;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  width: 186px;
  height: 160px;
  opacity: 0.6;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
}
.gft_van-image {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}
.gft_van-image__img {
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  border-style: none;
}
.gft_img {
  position: absolute;
  top: 15px;
  height: 80% !important;
}
.gft_view-right {
  display: flex;
  justify-content: flex-end;
  right: 22%;
  position: absolute;
}
.gft_view-price {
  margin-left: 16px;
}
.gft_saleCardItem_text_price {
  font-size: 14px;
  opacity: 0.8;
  margin-right: 10px;
  vertical-align: top;
  color: rgba(176, 87, 77, 0.8);
}
.gft_color-def {
  font-family: PingFangSC-Regular;
  font-weight: 700;
  font-size: 18px;
}
.gft_view-giftCardValue {
  width: 20%;
  position: absolute;
  left: auto;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  text-align: center;
}
.gft_view-value {
  line-height: 110px;
  text-align: center;
  color: rgba(176, 87, 77, 0.8);
}
.gft_color-def_bg {
  font-size: 24px;
  font-family: PingFangSC-Regular;
  font-weight: 700;
}
.gft_dash {
  position: absolute;
  content: "";
  top: 16px;
  bottom: 16px;
  left: 80%;
  border-left: 1.5px dashed rgba(176, 87, 77, 0.8);
}
.gft_view-times {
  padding-top: 34px;
  line-height: 36px;
  font-size: 28px;
  font-weight: 700;
}
.gft_view-tip {
  color: rgba(176, 87, 77, 0.6);
  text-align: center;
}

/* package card css  */
.package_view-bc {
  position: relative;
  z-index: 1;
  display: flex;
  height: 70px;
  padding: 16px 24px 0 16px;
}

.package_view-info {
  flex: 1;
  max-width: 100%;
}
.package_overflowText {
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.package_title {
  display: inline-block;
  font-family: SourceHanSansCN-Bold, PingFangSC-Semibold, PingFang SC;
  font-size: 16px;
  /* line-height: 32px; */
  font-weight: 700;
  color: rgba(11, 92, 95, 0.8);
  white-space: nowrap;
}
.package_saleCardItem_text_code {
  margin-left: 10px;
  vertical-align: top;
  color: rgba(11, 92, 95, 0.8);
  white-space: nowrap;
}
.package_view-apply {
  display: flex;
}
.package_view-cardApply {
  cursor: pointer;
  border-bottom: 1px solid;
  font-size: 12px;
  color: rgba(11, 92, 95, 0.8);
}
.package_bk-cardApply {
  display: inline-block;
  line-height: 20px;
}
.package_cardApply_text_applicable {
  vertical-align: top;
}
.package_icon-gengduo {
  font-size: 12px;
  margin-top: 1px;
  line-height: 20px;
  color: rgba(11, 92, 95, 0.8);
}
.package_view-singleUsageQty {
  display: flex;
  margin-left: 24px;
}
.package_saleCardItem_text_singleUsageQty {
  vertical-align: top;
  color: rgba(11, 92, 95, 0.8);
}
.package_display-def {
  position: relative;
  color: rgba(11, 92, 95, 0.8);
}
.package_view-bottom {
  position: relative;
  z-index: 1;
  display: flex;
  height: 40px;
  line-height: 40px;
  padding: 0 24px 0 16px;
  background: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0.3),
    hsla(0, 0%, 100%, 0)
  );
}
.package_view-left {
  flex: 1;
}
.package_bk-validDateText {
  font-size: 12px;
}
.package_saleCardItem_text_valid {
  vertical-align: top;
  font-size: 12px;
  color: rgba(11, 92, 95, 0.8);
}
.package_bk-date {
  font-size: 12px;
  color: rgba(11, 92, 95, 0.8);
}
.package_image-icon {
  position: absolute;
  left: auto;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  width: 186px;
  height: 160px;
  opacity: 0.6;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
}
.package_van-image {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}
.package_van-image__img {
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  border-style: none;
}
.package_view-right {
  display: flex;
  justify-content: flex-end;
  right: 27px;
  position: absolute;
}
.package_view-price {
  margin-left: 16px;
}
.package_saleCardItem_text_price {
  font-size: 14px;
  opacity: 0.8;
  margin-right: 10px;
  vertical-align: top;
  color: rgba(11, 92, 95, 0.8);
}
.package_color-def {
  font-family: PingFangSC-Regular;
  font-weight: 700;
  font-size: 18px;
  color: rgba(11, 92, 95, 0.8);
}
.package_view-giftCardValue {
  width: 20%;
  position: absolute;
  left: auto;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  text-align: center;
}
.package_color-def_bg {
  font-size: 24px;
  font-family: PingFangSC-Regular;
  font-weight: 700;
}
.package_dash {
  position: absolute;
  content: "";
  top: 16px;
  bottom: 16px;
  left: 80%;
  border-left: 1.5px dashed rgba(11, 92, 95, 0.8);
}
.package_view-times {
  padding-top: 34px;
  line-height: 36px;
  font-size: 28px;
  font-weight: 700;
}

.package_view-tip {
  font-size: 12px;
  color: rgba(11, 92, 95, 0.8);
}
.package_view-value {
  color: rgba(11, 92, 95, 0.8);
  text-align: right;
  font-size: 16px;
  font-weight: 700;
}
.editgiftcardvalue.ant-input-number .ant-input-number-input {
  height: 40px;
}
.editgiftcardvalue .ant-input-number-handler-wrap {
  width: 22px !important;
  opacity: 1 !important;
}

/* Ensure step buttons are styled correctly */
.editgiftcardvalue .ant-input-number-handler {
  display: block;
  transition: none;
}
.membership-tabs.ant-tabs > .ant-tabs-nav::before {
  border-bottom: none !important;
}
.membership-tabs.ant-tabs > .ant-tabs-nav {
  justify-content: center !important;
}
.membership-tabs.ant-tabs .ant-tabs-tab {
  padding: 0px !important;
}
.membership-tabs.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  background: #fff !important;
  padding: 8px 12px !important;
  border-radius: 8px !important;
}
.membership-inputnumber .ant-input-number-group {
  height: 45px !important;
}
.membership-inputnumber .ant-input-number-input-wrap {
  display: flex !important;
  height: 45px !important;
  align-items: center !important;
}
.ms_view-bc {
  position: relative;
  z-index: 1;
  display: flex;
  height: 70px;
  padding: 16px 24px 0 16px;
}
.ms_view-info {
  flex: 1;
  max-width: calc(58% - 40px);
}
.ms_overflowText {
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ms_title {
  display: inline-block;
  font-family: SourceHanSansCN-Bold, PingFangSC-Semibold, PingFang SC;
  font-size: 16px;
  /* line-height: 32px; */
  font-weight: 700;
  /* color: rgba(45, 73, 127, .8);
  rgba(93, 74, 140, .8);
  rgba(139, 88, 59, .8); */
  white-space: nowrap;
}
.ms_saleCardItem_text_code {
  margin-left: 10px;
  vertical-align: top;
  /* color: rgba(45, 73, 127, .8);
  rgba(93, 74, 140, .8);
  rgba(139, 88, 59, .8); */
  white-space: nowrap;
}
.ms_view-apply {
  display: flex;
}
.ms_view-cardApply {
  cursor: pointer;
  border-bottom: 1px solid;
  font-size: 12px;
  /* color: rgba(45, 73, 127, .8);
  rgba(93, 74, 140, .8);
  rgba(139, 88, 59, .8); */
}
.ms_bk-cardApply {
  display: inline-block;
  line-height: 20px;
}
.ms_cardApply_text_applicable {
  vertical-align: top;
}
.ms_icon-gengduo {
  font-size: 12px;
  margin-top: 1px;
  line-height: 20px;
}
.ms_view-bottom {
  position: relative;
  z-index: 1;
  display: flex;
  height: 40px;
  line-height: 40px;
  padding: 0 24px 0 16px;
  background: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0.3),
    hsla(0, 0%, 100%, 0)
  );
}
.ms_view-left {
  flex: 1;
}
.ms_bk-validDateText {
  font-size: 12px;
}
.ms_saleCardItem_text_valid {
  vertical-align: top;
  font-size: 12px;
  /* color: rgba(45, 73, 127, .8);
  rgba(93, 74, 140, .8);
  rgba(139, 88, 59, .8); */
}
.ms_bk-date {
  font-size: 12px;
  /* color: rgba(45, 73, 127, .8);
  rgba(93, 74, 140, .8);
  rgba(139, 88, 59, .8);*/
}
.ms_view-right {
  display: flex;
  justify-content: flex-end;
  right: 22%;
}
.ms_view-price {
  margin-left: 16px;
}
.ms_saleCardItem_text_price {
  font-size: 14px;
  opacity: 0.8;
  margin-right: 10px;
  vertical-align: top;
  /* color: rgba(45, 73, 127, .8);
  rgba(93, 74, 140, .8);
  rgba(139, 88, 59, .8);*/
}
.ms_color-def {
  font-family: PingFangSC-Regular;
  font-weight: 700;
  font-size: 18px;
}
.ms_display-def_view-item {
  margin-left: auto;
  min-width: 90px;
  width: 14%;
  margin-right: 20px;
  text-align: right;
}
.ms_view-tip {
  font-size: 12px;
}
.ms_view-value {
  text-align: right;
  font-size: 16px;
  font-weight: 700;
}
.ms_display-def_view-item_disc {
  margin-left: auto;
  margin-right: 0;
  min-width: 110px;
  width: 20%;
  text-align: right;
}
.ms_display-def_view-item_session {
  margin-left: auto;
  min-width: 90px;
  width: 14%;
  margin-right: 20px;
  text-align: right;
}
.worktag-radiogroup .ant-radio-button-wrapper:not(:first-child)::before {
  background: none !important;
}
.billdetail-modal .ant-modal-content {
  padding: 0px !important;
}
.billdetail-modal .ant-modal-close {
  top: 16px !important;
}
.billdetail-modal .ant-modal-header {
  padding: 20px 20px 0px 20px;
}
.billdetail-modal .ant-modal-body {
  height: calc(100vh - 280px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px 10px 20px 20px !important;
  scrollbar-width: thin;
}
.billdetail-modal .ant-modal-footer {
  border-top: 1px solid #d2d2d2;
  padding: 10px 20px;
}
.slider-kiv-taken.ant-slider-horizontal {
  border-radius: 10px !important;
  padding-block: 0px !important;
  height: 20px !important;
}
.slider-kiv-taken.ant-slider .ant-slider-rail {
  border-radius: 10px !important;
  height: 20px !important;
}
.slider-kiv-taken.ant-slider .ant-slider-track {
  border-radius: 10px !important;
  height: 20px !important;
}
.slider-kiv-taken.ant-slider .ant-slider-handle::before,
.slider-kiv-taken.ant-slider .ant-slider-handle::after {
  display: none !important;
}
