.image-upload-container {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}

.upload-grid {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-right: 20px;
}

.upload-box {
  width: 90px;
  height: 90px;
  border: 1px dashed #f4f5f7;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-color: #e0e7fe;
  /* margin-right: 20px; */
}

.upload-icon {
  font-size: 20px;
  color: #8c8c8c;
}

.image-preview {
  position: relative;
  width: 90px;
  height: 90px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  overflow: hidden;
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-icon {
  position: absolute;
  top: 4px;
  right: 4px;
  color: red;
  cursor: pointer;
  background-color: white;
  border-radius: 15%;
  padding: 1px;
}
