.Accounting_header_title {
  margin: 0;
  font-family: "SourceHanSansCN-Bold, PingFangSC-Semibold, PingFang SC";
  font-size: 18px;
  font-weight: 700;
}
.Accounting_print_button {
  color: rgb(102, 102, 102);
  height: 40px;
  padding: 9px 18px;
  background-color: white;
  border-color: "#e0e7fe";
  border-radius: 6px;
  font-weight: 700;
}
.Accounting_run_button {
  color: "white";
  height: 40px;
  padding: 9px 18px;
  background-color: "#2e56f2" !important;
  border-color: "#2e56f2" !important;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 400;
}
.Accounting_fold-expand_button {
  color: "#2e56f2";
  height: 40px;
  padding: 9px 18px;
  background-color: white;
  border: none;
  font-weight: 700;
}
.Accounting_receipt_card {
  margin-bottom: 16px;
  border: 1px solid #e0e7fe;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
}
.Accounting_title {
  font-weight: 700;
  font-size: 16px;
  font-family: SourceHanSansCN-Bold, PingFangSC-Semibold, PingFang SC;
}
.Accounting_sub_title {
  color: #999;
}
.Accounting_divider {
  border: 5px solid black;
  width: 60px;
  margin: 20px 0px;
}
