.section {
  background-color: #f5f7fa;
  padding: 10px 16px;
  border-radius: 8px;
  margin-top: 16px;
}
.radio-button-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
