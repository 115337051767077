/* 
.package-services-lable {
  padding-top: 10px;
  font-size: 16px;
  font-family: "SourceHanSansCN-Bold, PingFangSC-Semibold, PingFang SC";
  font-weight: 600;
}
.package-service-table-container {
  padding: 8px 10px;
}
.package-services-row-1 {
  padding: 8px 10px;
  background-color: white;
} */
.package-services-container {
  background-color: #f5f7fa;
  padding: 16px;
  border-radius: 8px;
  margin-top: 16px;
}

.package-services-lable {
  font-size: 16px;
  font-family: "SourceHanSansCN-Bold, PingFangSC-Semibold, PingFang SC";
  font-weight: 600;
}

.package-service-table-container {
  margin-top: 8px;
}

.package-services-header-row {
  font-weight: bold;
  padding: 8px 10px;
  background-color: #ffffff;
  border-bottom: 2px solid #e8e8e8;
}

.package-services-row {
  padding: 8px 10px;
  margin: 4px 0px;
  background-color: #ffffff;
  border-bottom: 1px solid #e8e8e8;
}

.package-service-name-col {
  display: flex;
  align-items: center;
}

.package-service-name {
  margin-left: 8px;
  font-size: 14px;
}

.dollar-prefix {
  color: rgba(46, 86, 242, 0.5);
  font-weight: 700;
}
