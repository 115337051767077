/* MembershipModel.css */
.visit-based-container {
  background-color: #f5f7fa;
  padding: 16px;
  border-radius: 8px;
  margin-top: 16px;
}

.price-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  margin-bottom: 16px;
}

.price-display span {
  font-weight: bold;
}

.prepaid-container {
  background-color: #f5f7fa;
  padding: 16px;
  border-radius: 8px;
  margin-top: 16px;
}

.ant-input,
.ant-select {
  width: 100%;
}
/* tab  */

.ant-tabs-nav-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
/* validity-section */
.validity-section {
  background-color: #f5f7fa;
  padding: 16px;
  border-radius: 8px;
  margin-top: 16px;
}

.ant-form-item {
  margin-bottom: 20px;
}

h3 {
  font-weight: 600;
  color: #1f1f1f;
  margin-bottom: 16px;
}

.radio-text {
  display: inline-block;
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Hides overflowed text */
  text-overflow: ellipsis; /* Adds ellipsis (...) to the overflowed text */
}

.ant-tabs-tab {
  font-size: 16px;
  font-weight: 500;
}

.ant-alert {
  font-size: 14px; /* Adjust the font size for the alert message */
  margin-bottom: 10px;
}

.ant-radio-wrapper {
  margin-right: 10px; /* Adjust the space between radio buttons */
}

.ant-radio {
  font-size: 14px;
}
