.billdetail_content {
    margin: auto;
    left: 0;
    right: 0;
    margin-top: 10px;
    border-radius: 12px;
    box-sizing: border-box;
    max-width: 1280px;
    min-width: 920px;
    height: calc(100% - 40px);
    padding: 24px;
    overflow-y: auto;
    border: 0.5px solid #e0e7fe;
    background-color: hsla(0, 0%, 100%, 0.5);
  }
  .billing_title {
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
  }
  .view-orderBaseInfo {
    display: flex;
    gap: 45px;
    padding: 16px 0;
  }
  
  .key-billNo {
    flex: 2;
    line-height: 32px;
    box-sizing: border-box;
  }
  
  .orderDetail_title_billId {
    color: #666;
    margin-right: 5px;
    vertical-align: top;
    font-size: 12px;
  }
  
  .display_def_view-value {
    display: inline;
    font-weight: 700;
  }
  
  .spn_refno {
    font-size: 14px;
    color: #333;
  }
  .view-tag_pay_paid {
    display: inline;
    margin-left: 10px;
    font-size: 12px;
    color: #fff;
    background: #00ad6f;
    padding: 0 8px;
    border-radius: 4px;
    font-weight: 700;
  }
  .view-tag_pay_voided {
    display: inline;
    margin-left: 10px;
    font-size: 10px;
    color: #fff;
    background: #dcdcdc;
    padding: 0 8px;
    border-radius: 4px;
    font-weight: 700;
  }
  
  .key-updateUser {
    flex: 1;
    line-height: 32px;
    box-sizing: border-box;
  }
  .orderDetail_title_cashier {
    color: #666;
    margin-right: 5px;
    vertical-align: top;
    font-size: 14px;
  }
  .view-value_cashier {
    display: inline;
    font-weight: 700;
  }
  .key-billDate {
    flex: 1;
    line-height: 32px;
    min-width: 220px;
  }
  .orderDetail_title_billDate {
    color: #666;
    margin-right: 5px;
    vertical-align: top;
  }
  .view-value_date {
    display: inline;
    font-weight: 700;
  }
  .key-payTimeText {
    flex: 1;
    line-height: 32px;
  }
  .orderDetail_title_payTime {
    color: #666;
    margin-right: 5px;
    vertical-align: top;
  }
  .view-value_time {
    display: inline;
    font-weight: 700;
  }
  
  .view-customerInfo {
    display: flex;
  }
  .view-orderDetailCustomer {
    width: 268px;
    margin-right: 24px;
    border-radius: 12px;
    color: #fff;
    padding: 10px 0 0 24px !important;
    height: 72px;
  }
  
  .display_def {
    display: flex;
  
    padding: 4px 0;
  }
  
  .orderDetail_text_count {
    color: hsla(0, 0%, 100%, 0.65);
    min-width: 96px;
  }
  .view-value_gender {
    display: flex;
    flex: 1;
  }
  .view-item_male {
    flex: 1;
  }
  .orderDetail_text_male {
    color: hsla(0, 0%, 100%, 0.65);
  }
  .view-item_female {
    flex: 1;
    box-sizing: border-box;
  }
  .orderDetail_text_female {
    color: hsla(0, 0%, 100%, 0.65);
  }
  .display_def_cussource {
    display: flex;
    padding: 4px 0;
  }
  .orderDetail_text_clientSource {
    color: hsla(0, 0%, 100%, 0.65);
    min-width: 96px;
    margin-right: 5px;
  }
  .view-value_src {
    display: flex;
    flex: 1;
  }
  .view-memberDetail {
    flex: 1;
    height: 72px;
    color: #fff;
    padding: 12px 12px 0 24px !important;
    border-radius: 10px;
  }
  .display_def_view-info {
    display: flex;
    margin-bottom: 5px;
    padding-right: 40px;
  }
  .view-avatar {
    width: 40px;
    padding-top: 4px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
  }
  .display_def_view-text {
    flex: 1;
    margin-left: 24px;
  }
  .memberInfo_label_name {
    color: hsla(0, 0%, 100%, 0.65);
    line-height: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  .labelType-flex {
    display: flex;
    margin-bottom: 10px;
  }
  .view-value_cus {
    color: #fff;
  }
  .view-flex_dtl {
    display: flex;
    margin-bottom: 6px;
  }
  .labelType-flex {
    flex: 1;
    display: inline-flex;
    border-radius: 5px;
    color: hsla(0, 0%, 100%, 0.65);
    line-height: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  .memberInfo_label_phone {
    color: hsla(0, 0%, 100%, 0.65);
    line-height: 100%;
    text-align: left;
    margin-right: 10px;
  }
  .labelType-flex_view-phone {
    flex: 1;
    display: inline-flex;
    border-radius: 5px;
    color: hsla(0, 0%, 100%, 0.65);
    line-height: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  .memberInfo_email {
    color: hsla(0, 0%, 100%, 0.65);
    line-height: 100%;
    text-align: left;
    margin-right: 10px;
  }
  .view-title {
    padding: 12px 0;
  }
  .bk-title {
    display: inline-block;
    font-family: SourceHanSansCN-Bold, PingFangSC-Semibold, PingFang SC;
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
  }
  .view-saleDetails {
    margin-top: 16px;
    box-sizing: border-box;
  }
  .service {
    padding-left: 42px;
  }
  .bk-title_svr {
    display: inline-block;
    font-family: SourceHanSansCN-Bold, PingFangSC-Semibold, PingFang SC;
    font-size: 16px;
    /* line-height: 32px; */
    font-weight: 700;
  }
  .view-subTitle_project {
    display: flex;
  }
  .view-orderDetailSaleItem {
    background: #fff;
    border-radius: 8px;
    margin-bottom: 12px;
    padding: 0 18px;
  }
  .view-goodsInfo {
    display: flex;
    line-height: 32px;
    padding-top: 6px;
  }
  .view-saleEmp {
    font-size: 12px;
    color: #666;
    box-sizing: border-box;
  }
  .view-payInfo {
    margin-top: 8px;
    padding: 12px 0;
  }
  .view-name {
    flex: 1;
  }
  .view-standPrice {
    color: #666;
    font-size: 12px;
    font-weight: 700;
  }
  .bk_title_servie {
    font-size: 14px;
    font-weight: 700;
  }
  .span-count {
    margin-left: 24px;
    font-size: 14px;
  }
  .lang-standPrice {
    margin-right: 5px;
  }
  .bk-price_color-def {
    font-weight: 700;
    margin-left: 4px;
  }
  .span-unit {
    margin-right: 2px;
  }
  .span-value {
    font-size: 14px;
  }
  .view-saleEmp-main {
    display: flex;
    padding: 12px;
    background: #f6f7fa;
    position: relative;
  }
  .view-saleEmp-info {
    flex: 1;
    margin-left: 3px;
  }
  .view-saleEmp-item {
    display: flex;
    margin-bottom: 4px;
  }
  .view-name_typ {
    width: 300px;
  }
  /* .view-serviceCodeText {
    width: 86px;
  } */
  .view-tag {
    display: inline-flex;
    align-items: center;
    height: 18px;
    background: rgba(46, 86, 242, 0.1);
    border-radius: 4px;
    border: 1px solid #2e56f2;
    padding: 0 4px;
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #2e56f2;
  }
  .view-achievement {
    display: flex;
    margin-left: 48px;
  }
  
  .infocircle {
    margin-left: 4px;
    font-size: 17px;
    color: #2e56f2;
  }
  
  .btn-editStaff {
    height: 30px;
    vertical-align: top;
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 32px;
    min-width: 32px;
    line-height: 30px;
    border-radius: 6px;
    cursor: pointer;
  }
  .homeouli {
    color: #2e56f2;
    font-size: 20px !important;
    vertical-align: middle;
    line-height: 30px !important;
  }
  .view-payment {
    text-align: right;
    padding: 6px 0;
  }
  .view-amount {
    display: inline-block;
    text-align: left;
    min-width: 250px;
    margin-right: 20px;
    font-size: 14px;
    font-weight: 700;
  }
  .view-tip {
    display: inline-block;
    min-width: 140px;
    color: #999;
    text-align: right;
    padding-right: 10px;
  }
  .bk-price_color-def {
    font-weight: 700;
  }
  .view-payAmount {
    display: inline-block;
    text-align: left;
    min-width: 250px;
    margin-right: 20px;
    font-size: 14px;
    font-weight: 700;
  }
  .view-payText {
    margin-right: 0;
    display: inline-block;
    text-align: left;
    min-width: 250px;
    /* margin-right: 20px; */
    font-size: 14px;
    font-weight: 700;
  }
  .display_def_bk-image {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    margin-right: 5px;
  }
  .van-image {
    display: block;
    width: 100%;
    height: 100%;
  }
  .van-image__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
    border-style: none;
  }
  .view_bdr {
    margin-top: 8px;
    content: "";
    left: 0;
    right: 0;
    bottom: auto;
    height: 0.5px;
    width: auto;
    background: #e0e7fe;
  }
  .view-paymentSummary {
    margin-top: 16px;
  }
  .view-orderDetailPaymentSummary {
    display: flex;
    background: #fff;
    padding: 18px 18px 18px 18px;
  }
  .view-payInformation {
    flex: 3;
    margin-right: 18px;
    min-height: 120px;
    background: #fff;
    border-radius: 8px;
  }
  .view-sortName {
    width: 20px;
    height: 20px;
    top: 5px;
    padding-top: 2px;
    color: #fff;
    background: #2e56f2;
    border-radius: 2px;
    margin-right: 10px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
  }
  .view-payAmt {
    display: flex;
    flex: 2.5;
  }
  .bk-price_color-def {
    font-family: PingFangSC-Regular;
    font-weight: 700;
    font-size: 14px;
  }
  .view-name_pay {
    flex: 1.5;
    font-weight: 700;
    font-size: 14px;
  }
  .view-payItem_list {
    display: flex;
    padding: 8px 0;
    align-items: center;
  }
  .view-rightBox {
    min-width: 280px;
    min-height: 120px;
    background: #fff;
    border-radius: 8px;
  }
  .view-amountInfo {
    padding: 8px 18px;
    background: #f6f7fa;
    border-radius: 0 !important;
  }
  .view-subtotal {
    display: flex;
    padding: 8px 0;
  }
  .view-taxes {
    display: flex;
    padding: 8px 0;
  }
  .view-serviceCharge {
    display: flex;
    padding: 8px 0;
  }
  .view-total {
    display: flex;
    padding: 8px 0;
  }
  .view-tips {
    display: flex;
    padding: 8px 0;
  }
  .view-left {
    flex: 1;
    font-weight: 700;
    font-size: 14px;
  }
  .view-value {
    font-size: 14px;
  }
  .view-taxes {
    display: flex;
    padding: 8px 0;
  }
  .view-value_total {
    color: #f43b00;
    font-weight: 700;
  }
  .view_group_note_seg_img {
    margin-top: 16px;
  }
  .view_flex_note_seg {
    display: flex;
  }
  .view-notes {
    flex: 1;
    margin-right: 18px;
  }
  .view-title_note {
    display: flex;
    align-items: center;
  }
  .view-remark {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #e0e7fe;
    min-height: 150px;
    padding: 10px 18px;
  }
  .view-signature {
    flex: 1;
    margin-right: 0px;
  }
  .bk-title_note {
    display: inline-block;
    font-size: 16px;
    font-family: SourceHanSansCN-Bold, PingFangSC-Semibold, PingFang SC;
    line-height: 32px;
    font-weight: 700;
    vertical-align: top;
    margin-right: 5px;
  }
  .view-btn-edt {
    height: 100%;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .view-title_signature {
    /* display: inline-block; */
    font-family: SourceHanSansCN-Bold, PingFangSC-Semibold, PingFang SC;
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
    vertical-align: top;
    display: flex;
    align-items: center;
  }
  .view-img_sig {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.45);
    background: #fff;
    border-radius: 8px;
    border: 1px solid #e0e7fe;
    min-height: 150px;
    padding: 10px 18px;
  }
  .bk-image_sig {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 200px;
  }
  .van_img_sig {
    display: block;
    width: 100%;
    height: 100%;
  }
  .view-remarkImgs {
    margin-top: 12px;
    background: #fff;
    border-radius: 8px;
    padding: 12px 16px 12px 16px;
    border: 1px solid #e0e7fe;
    display: flex;
    gap: 3px;
  }
  .bk-upload-remarkImgs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 96px;
    background-color: #f4f5f7;
    border: 1px dashed #e0e7fe;
    border-radius: 6px;
    margin-bottom: 12px;
  }
  
  .bk-image_img {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
  }
  .van-image_up {
    display: block;
    width: 100%;
    height: 100%;
  }
  
  .remove-img-button {
    /* position: absolute;
    top: -5px;
    right: 0px;
    background: #f4f5f7;
    color: black;
    border: none;
    border-radius: 20%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    font-size: 12px; */
    display: flex;
    flex-direction: "row";
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 16px;
    height: 16px;
    border: none;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    border-radius: 2px 2px 2px 2px;
    cursor: pointer;
  }
  .orderCalcDetail-dropdown-main {
    width: 328px;
    max-height: 50vh;
    overflow-y: auto;
    background: hsla(0, 0%, 100%, 0.9);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.12),
      0 4px 5px 0 rgba(0, 0, 0, 0.08), 0 1px 10px 0 rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 13px;
    color: #666;
  }
  .view-title-main {
    margin: 0 12px;
    padding: 12px 0 8px 0;
    border-bottom: 1px solid #d4ddf8;
  }
  .bk-title_ordcal {
    display: inline-block;
    font-family: SourceHanSansCN-Bold, PingFangSC-Semibold, PingFang SC;
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
    vertical-align: top;
  }
  .view-container {
    position: relative;
  }
  .view-cell-item {
    padding: 12px 12px 16px;
  }
  .view-cash-main {
    position: relative;
  }
  .view-pay-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f6f7fa;
    padding: 6px 12px;
    margin-bottom: 16px;
  }
  .view-label_ordcal {
    flex: 1;
    min-width: 0;
    margin-left: 4px;
  }
  .view_sn {
    font-family: PingFangSC-Regular;
    font-weight: 700;
    font-size: 13px;
    color: #666;
  }
  .view-list {
    margin-bottom: 12px;
  }
  .view-item_in {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 13px;
    color: #666;
  }
  .repaymentDetail_all {
    vertical-align: top;
  }
  .view_vale {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 13px;
    color: #333;
  }
  .view-item_cal_time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 13px;
    color: #666;
  }
  .display_current_privilege_section {
    margin-top: 32px;
  }
  .current_privilege_view-memberRights {
    display: flex;
    width: 100%;
    background: #fff;
    text-align: center;
    border-radius: 12px;
    min-height: 72px;
  }
  .current_privilege_display-def {
    flex: 1.2;
    min-width: 300px;
    text-align: left;
    padding-left: 32px;
    border-right: 1 px solid #e0e7fe;
    position: relative;
  }
  .current_privilege_def {
    padding: 21px 0 0 0;
    line-height: 28px;
    font-weight: 700;
    font-size: 12px;
    color: #999;
    height: 72px;
    position: relative;
  }
  .current_privilege_color-def {
    color: #333;
    margin-left: 10px;
    font-weight: 700;
    font-size: 18px;
  }
  .current_privilege_view-storeBalance {
    margin-top: -2px;
    line-height: 20px;
  }
  .current_privilege-orderDetail_text_memberStoreBalance {
    font-weight: 700;
    font-size: 12px;
    color: #999;
  }
  .current_privilege_bk-image {
    height: 28px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
  }
  .current_privilege_van_img {
    display: block;
    width: 100%;
    height: 100%;
  }
  .current_privilege_van-image__img {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    border-style: none;
  }
  .current_privilege_line {
    content: "";
    /* position: absolute; */
    left: 0;
    right: auto;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 0.5px;
    height: 24px;
    background: #e0e7fe;
  }
  .membership_card_display-def {
    position: relative;
    flex: 1;
    text-align: center;
  }
  .membership_card_orderDetail_text {
    vertical-align: top;
    line-height: 28px;
    font-weight: 700;
    font-size: 12px;
    color: #999;
  }
  .membership_card_def {
    margin-top: 11px;
    margin-bottom: -11px;
    padding: 21px 0 0 0;
    line-height: 28px;
    font-weight: 700;
    font-size: 12px;
    color: #999;
    height: 72px;
  }
  .membership_card_bk-image {
    height: 28px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
  }
  .membership_card_van-image {
    display: block;
    width: 100%;
    height: 100%;
  }
  .membership_card_van-image__img {
    display: block;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    border-style: none;
  }
  