/* for profile page start */
.header-box {
  background-color: #ffa500;
  color: #ffffff;
  padding: 16px;
  height: 150px;
  position: relative;
  display: flex;
  /* justify-content: center; */
}
.header-fix {
  position: absolute;
  top: 30px;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  gap: 10px;
}
.user-card-details {
  margin-top: -80px;
  margin-right: 20px;
  margin-left: 20px;
  padding: 0px;
}
.user-card-box {
  border-radius: 20px;
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.user-card {
  text-align: center;
  width: -webkit-fill-available !important;
}
.user-title {
  color: #ffffff !important;
  margin: 0px !important;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica,
    Segoe UI, Arial, Roboto, PingFang SC, miui, Hiragino Sans GB,
    Microsoft Yahei, sans-serif;
}
.signout-button {
  margin-bottom: 80px;
  display: flex;
  justify-content: center;
}
/* for profile page end */
.mobile-dropdown .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}
.mobile-dropdown .ant-select-selection-item,
.mobile-dropdown .ant-select-selection-placeholder {
  text-align: right !important;
}
.mobile-inputtext.ant-input {
  border: none !important;
  box-shadow: none !important;
  text-align: right !important;
}
